<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';

export default {
    name: 'App',
    setup() {
        const router = useRouter();
        const isApp = computed(() => process.env.MODE === 'cordova');

        if (process.env.CLIENT) {
            if(isApp.value) {
                router.push('/');
            } else {
                window.open('https://api.etabl.ru/api/v1/qr-codes/redirect', '_self');
            }
        };
        return {
            isApp,
        };
    },
};
</script>